import React, {Fragment, useEffect} from 'react';
import {Switch, useLocation} from "react-router-dom";
import routes from "../../config/routes";
import {Layout, Spin} from 'antd'
import AppRoute from "../../components/route/AppRoute";
import {useAuthState} from "../../context";
import {Sidebar} from '../../components/sidebar';
import {isLogined} from "../../helper/utils";

const {Content,} = Layout

export const AppContainer = (props) => {
  const {loading, profile} = useAuthState();
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <Switch>
        <Layout>
          {
            isLogined(profile) && <Sidebar/>
          }
          <Layout
            style={{
              width: isLogined(profile) ? 'calc(100% - 200px)' : '100%',
              position: 'absolute',
              left: isLogined(profile) ? '200px' : 0,
            }}
          >
            <Content>
              <Spin spinning={loading} size="large">
                {routes.map((route) => (
                  <AppRoute
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    isPrivate={route.isPrivate}
                  />
                ))}
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </Switch>
    </Fragment>
  )
}
