export const getTOBString = (tob) => {
  switch (tob) {
    case 1: return "税理士・会計事務所";
    case 2: return "中小企業診断士";
    case 3: return "その他士業";
    case 4: return "金融機関";
    case 5: return "一般企業（金融機関以外）";
    case 6: return "その他";
    default: return "";
  }
}
