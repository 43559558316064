/**
 * Axios related configurations here
 */

require('dotenv').config()

export const BASE_URL = process.env.API_BASE_URL || 'https://api.cloudplanner.jp/'

export const LOGIN = `admin/login`
export const EXCHANGE = `admin/exchange`
export const SPECIALISTS = 'admin/specialists'
export const CLIENTS = 'admin/clients'
