import React, {Fragment} from "react";
import {Form, Input} from "antd";
import {injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {useAuthDispatch} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {login} from "../../api/axiosAPIs";
import {updateProfile} from "../../helper/utils";

function LoginBase(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch();

  const onFinish = async (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    let response = {};
    try {
      response = await login(formData);
      if (response.status === 200) {
        if (response.data.user) {
          updateProfile(dispatch, response.data.user);
          history.push(PageConstant.HOME);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-12 flex items-center h-screen"}>
        <div
          className="bg-white mx-auto w-full md:w-500px"
          style={{borderRadius: 20, overflow: "hidden", border: '1px solid gray'}}
        >
          <div
            className={"flex items-center justify-center bg-blue"}
            style={{height: 75}}
          >
            <span className={"text-xl text-white font-bold"}>ログイン</span>
          </div>
          <Form onFinish={onFinish} className={"p-6i md:p-35pxi"}>
            {/*ID（メールアドレス）*/}
            <div className={"mb-2"}>
              <span className={"font-bold text-sm"}>ID（メールアドレス）</span>
            </div>
            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  message: props.intl.formatMessage({
                    id: "alert.fieldRequired",
                  }),
                },
              ]}
            >
              <Input
                placeholder={"メールアドレスを入力"}
                style={{height: 45}}
              />
            </Form.Item>
            {/*パスワード*/}
            <div className={"mb-2"}>
              <span className={"font-bold text-sm"}>パスワード</span>
            </div>
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: props.intl.formatMessage({
                    id: "alert.fieldRequired",
                  }),
                },
              ]}
            >
              <Input.Password
                placeholder={"パスワードを入力"}
                style={{height: 45}}
              />
            </Form.Item>
            <div className={"flex justify-center"}>
              <button
                type="submit"
                className={
                  "flex items-center justify-center bg-blue rounded btn-primary-semi-round text-white text-lg w-full md:w-315px"
                }
                style={{height: 55}}
              >
                <span>ログイン</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(LoginBase);
