import {PageConstant} from "../constants/PageConstant";
import {Login, Logout} from '../pages/login';
import {Specialists} from '../pages/specialists';
import {Clients} from '../pages/clients';
import {Error404} from '../pages/common';

const routes = [
  {path: PageConstant.LOGIN, component: Login, isPrivate: false},
  {path: PageConstant.LOGOUT, component: Logout, isPrivate: false},
  {path: PageConstant.HOME, component: Specialists, isPrivate: true},
  {path: PageConstant.CLIENTS, component: Clients, isPrivate: true},
  {path: PageConstant._404, component: Error404, isPrivate: false},
];

export default routes;
