import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import {getTOBString} from '../../constants/TypeOfBusiness'
import {FormattedMessage, injectIntl} from 'react-intl'

/**
 * Table pagination Component style sheets
 */
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
})

const TablePaginationActions = (props) => {
  /**
   * Handle function for click first page button
   */
  const handleFirstPageButtonClick = event => {
    props.onPageChange(event, 0)
  }

  /**
   * Handle function for click back page button
   */
   const handleBackButtonClick = event => {
    props.onPageChange(event, props.page - 1)
  }

  /**
   * Handle function for click next page button
   */
   const handleNextButtonClick = event => {
    props.onPageChange(event, props.page + 1)
  }

  /**
   * Handle function for click last page button
   */
   const handleLastPageButtonClick = event => {
    props.onPageChange(
      event,
      Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)
    )
  }

  /**
   * Render function to view this component
   */

    return (
        <div className={props.classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={props.page === 0}
                aria-label="First Page"
            >
                <FirstPageIcon/>
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={props.page === 0}
                aria-label="Previous Page"
            >
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
                aria-label="Next Page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
                aria-label="Last Page"
            >
                <LastPageIcon/>
            </IconButton>
        </div>
    )
}

/**
 * Set prop types for table pagination actions
 */
TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {withTheme: true})(
  TablePaginationActions
)

const SpecialistList = (props) => {

    /**
     * Handle function for change page
     */
    const handleChangePage = (event, page) => {
        props.onPageChange(event, page)
    }

    /**
     * Handle function for change rows per page
     */
    const handleChangeRowsPerPage = event => {
        props.onRowsPerPageChange(event)
    }

    /**
    * Render function to view this component
    */
    const headers = [
        {
            id: 0,
            title: props.intl.formatMessage({id: 'table.header.no'})
        },
        {
            id: 1,
            title: props.intl.formatMessage({id: 'table.header.specialist.id'})
        },
        {
            id: 2,
            title: props.intl.formatMessage({id: 'table.header.email'})
        },
        {
            id: 3,
            title: props.intl.formatMessage({id: 'table.header.conpany.name'})
        },
        {
            id: 4,
            title: props.intl.formatMessage({id: 'table.header.type.of.business'})
        },
        {
            id: 5,
            title: props.intl.formatMessage({id: 'table.header.position'})
        },
        {
            id: 6,
            title: props.intl.formatMessage({id: 'table.header.name'})
        },
        {
            id: 7,
            title: ""
        },
    ]
    return (
        <div className={""}>
        <Table className={""}>
            <TableHead>
            <TableRow>
                {headers.map(header => {
                return (
                    <TableCell className={"bg-blue font-bold"} style={{color: 'white'}} key={header.id}>
                    {header.title}
                    </TableCell>
                )
                })}
            </TableRow>
            </TableHead>
            <TableBody>
            {
                !props.rows.length &&
                <TableRow>
                <TableCell colSpan={8}>
                    <div className={"text-center"}>{props.intl.formatMessage({id: 'table.noData'})}</div>
                </TableCell>
                </TableRow>
            }
            {props.rows.map((row, key) => {
                return (
                <TableRow
                    hover
                    key={key}>
                    <TableCell>{(props.page * props.rowsPerPage) + key + 1}</TableCell>
                    <TableCell>{/*row.uid*/}＊＊＊＊＊＊＊＊＊＊</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>{getTOBString(row.type_of_business)}</TableCell>
                    <TableCell>{row.position}</TableCell>
                    <TableCell>{row.first_name + row.last_name}</TableCell>
                    <TableCell>
                    <button 
                    className="bg-blue-600 text-white py-2 rounded font-semibold"
                    style={{width: 100}}
                            onClick={() => props.onClickLoginButton(row.user_id)}>
                        <FormattedMessage id="str.login"/>
                    </button>
                    </TableCell>
                </TableRow>
                )
            })}
            </TableBody>
        </Table>
        <div>
            <TablePagination
                component="div"
                count={props.count}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                rowsPerPageOptions={props.rowsPerPageOptions}
                labelRowsPerPage={props.intl.formatMessage({id: 'table.rows.per.page'})}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
            />
        </div>
        </div>
    )
}

export default (injectIntl(SpecialistList))
