import React, {Fragment, useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl'
import {exchange, getSpecialists} from '../../api/axiosAPIs';
import {Card, Input} from 'antd'
import SpecialistList from '../../components/specialist/specialistList'
import {useAuthDispatch} from '../../context';
import {logout} from '../../helper/utils'
import _ from 'lodash'

function Specialists(props) {
  const [state, setState] = useState({page: 0, per_page: 10, keyword: ''})
  const [users, setUsers] = useState({data: [], total: 0})
  const dispatch = useAuthDispatch();

  useEffect(() => {
    fetchSpecialistList(state)
  }, []);

  /**
   * fetch Data
   */
  const fetchSpecialistList = (data) => {
    getSpecialists(data)
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setUsers(response.data)
            setState(data)
          }
        }
      })
  }

  const getParam = () => {
    return {...state}
  }

  /**
   * Handle function for change page
   */
  const handleChangePage = (event, page) => {
    let data = getParam()
    data.page = page
    fetchSpecialistList(data)
  }

  /**
   * Handle function for change rows per page
   */
  const handleChangeRowsPerPage = (event) => {
    let data = getParam()
    data.page = 0
    data.per_page = event.target.value
    fetchSpecialistList(data)
  }

  /**
   * Handle function for Login
   */
  const onClickLoginButton = (userId) => {
    const BASE_URL = process.env.FRONTEND_BASE_URL || 'https://www.cloudplanner.jp'
    window.location.replace(BASE_URL + "/login")
  }

  const onChangeKeywordValue = (e) => {
    setState({...state, keyword: e.target.value})
  }

  /**
   * Handle function for deny members
   */
  const onClickSearchButton = () => {
    let data = getParam()
    data.page = 0
    fetchSpecialistList(data)
  }

  return (
    <Fragment>
      <Card>
        <div className="flex justify-between pb-4">
          <h1><FormattedMessage id="str.specialist.list"/></h1>
          <button className="bg-blue-600 text-white px-4 rounded my-3 font-semibold " style={{width: 120}}
                  onClick={() => logout(dispatch)}>
            <FormattedMessage id="auth.logout"/>
          </button>
        </div>

        <div className="flex pb-4">
          <Input placeholder="氏名、メールアドレス、会社名" className="mr-4" style={{width: 400}}
                 onChange={(e) => onChangeKeywordValue(e)}/>
          <button className="bg-blue-600 text-white px-4 rounded-sm font-semibold" style={{width: 80}}
                  onClick={onClickSearchButton}>
            <FormattedMessage id="btn.search"/>
          </button>
        </div>

        <SpecialistList
          rows={users.data}
          count={users.total}
          page={state.page}
          rowsPerPage={state.per_page}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onClickLoginButton={onClickLoginButton}
        />
      </Card>
    </Fragment>
  )
}

export default injectIntl(Specialists)