import {SET_PROFILE} from "../constants/ActionType";
import {initialProfile} from "../context/reducer";

export const isLogined = (profile) => {
  return Boolean(profile.uid)
}

export const logout = (dispatch) => {
  updateProfile(dispatch, initialProfile);
}

export const updateProfile = (dispatch, payload) => {
  dispatch({type: SET_PROFILE, payload: payload});
  localStorage.setItem('currentProfile', JSON.stringify(payload));
}

export const Trim = (data) => {
  return data.trim().replace(/\\n/g, '')
}
