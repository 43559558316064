import React, {Fragment} from 'react';
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import {SidebarLogo} from './SidebarLogo'
import { PageConstant } from '../../constants/PageConstant';
import {injectIntl} from 'react-intl'

const SidebarContent = (props) => {
  return (
      <div className="side-bar-content h-screen">
        <SidebarLogo/>
        <div>
            <Menu
             className="side-bar-content">
                  <Menu.Item key={PageConstant.HOME}>
                    <Link to={PageConstant.HOME}>
                      <span>{props.intl.formatMessage({id: 'str.specialist.list'})}</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={PageConstant.CLIENTS}>
                    <Link to={PageConstant.CLIENTS}>
                      <span>{props.intl.formatMessage({id: 'str.client.list'})}</span>
                    </Link>
                  </Menu.Item>
            </Menu>
        </div>
      </div>
  )
}

export default (injectIntl(SidebarContent))
