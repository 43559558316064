import axios from "axios";
import * as axiosConfig from "./axiosConfig";

export const axiosRequest = (
  method,
  url,
  reqData = null,
  needLoader = true
) => {
  const auth = localStorage.getItem("currentProfile")
    ? JSON.parse(localStorage.getItem("currentProfile")).uid
    : undefined;

  const getHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json; charset=utf-8",
    accessUid: auth,
  };

  const formDataHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    accessUid: auth,
  };

  const jsonHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    accessUid: auth,
  };

  let reqConfig = {
    url: url,
    method: method,
    baseURL: axiosConfig.BASE_URL,
    withCredentials: true,
    needLoader: needLoader, // custom config for show loader
    headers: getHeaders, // default: get
    timeout: 300000,
  };
  if (method === "post" || method === "put" || method === "patch") {
    const headers = reqData instanceof FormData ? formDataHeaders : jsonHeaders;
    reqConfig["data"] = reqData;
    reqConfig["headers"] = headers;
  } else {
    // 'get', 'delete', 'head'
    reqConfig["params"] = reqData;
  }
  return axios.request(reqConfig);
};

export const login = (data) => {
  return axiosRequest("post", axiosConfig.LOGIN, data);
};

export const getSpecialists = (data) => {
  const params = {...data}
  params.page = params.page + 1;
  return axiosRequest("get", axiosConfig.SPECIALISTS, params);
};

export const getClients = (data) => {
  const params = {...data}
  params.page = params.page + 1;
  return axiosRequest("get", axiosConfig.CLIENTS, params);
};

export const exchange = (id) => {
  return axiosRequest("post", axiosConfig.EXCHANGE + '/' + id);
};

