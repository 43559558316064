import { icons } from 'antd/lib/image/PreviewGroup';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom'
import {CloudSyncOutlined} from '@ant-design/icons'
import { PageConstant } from '../../constants/PageConstant';

export const SidebarLogo = (props) => {
    return (
      <div className="py-4">
        <Link to={PageConstant.HOME} className="text-white">
          <span className='text-lg pl-4'><CloudSyncOutlined className='pr-2' style={{verticalAlign: 'middle'}}/>Cloud Planner</span>
        </Link>
      </div>
    )
}

