import React from 'react';
import { Layout } from 'antd'
import SidebarContent from './SidebarContent'

const { Sider } = Layout

export const Sidebar = (props) => {
    return (
      <Sider
        width={200}
        style={{ 
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
      }}
  
        >
          <SidebarContent/>
      </Sider>
      )
}
